export const QUALIFICATIONS = [
    {
        divider: true,
        text: "2022"
    },
    {
        title: "Front-End Development Libraries",
        subheading: "FreeCodeCamp",
        id: "fcc-front-end-development-libraries",
        date: "September 2022",
        body: [
            {
                type: "p",
                text: "Developer Certification, representing approximately 300 hours of coursework."
            },
            {
                type: "a",
                text: "View Certificate",
                link: "https://www.freecodecamp.org/certification/Brodie_Griggs/front-end-development-libraries"
            },
            {
                type: "h4",
                text: "Modules Covered:"
            },
            {
                type: "ul",
                text: [
                    "Bootstrap",
                    "jQuery",
                    "SASS",
                    "React",
                    "Redux",
                    "React and Redux",
                    "Front End Development Libraries Projects"
                ]
            }
        ]
    },
    {
        title: "Web Development",
        subheading: "mimo",
        id: "m-web-development",
        date: "September 2022",
        body: [
            {
                type: "p",
                text: "Certificate of Achievement"
            },
            {
                type: "a",
                text: "View Certificate",
                link: "../view-certificate",
                params: [
                    "name=mimo-web-development-certificate.png"
                ]
            },
            {
                type: "h4",
                text: "Curriculum providing:"
            },
            {
                type: "ul",
                text: [
                    "An understanding of the core concepts of programming using JavaScript, the HTML knowledge required to create web pages, and the ability to style pages using CSS.",
                    "The practical experience needed to combine these technologies and publish a website on the internet."
                ]
            }
        ]
    },
    {
        title: "SQL",
        subheading: "mimo",
        id: "m-sql",
        date: "August 2022",
        body: [
            {
                type: "p",
                text: "Certificate of Achievement"
            },
            {
                type: "a",
                text: "View Certificate",
                link: "../view-certificate",
                params: [
                    "name=mimo-sql-certificate.png"
                ]
            },
            {
                type: "h4",
                text: "Curriculum providing:"
            },
            {
                type: "ul",
                text: [
                    "An understanding of the core concepts of SQL required to create tables and gain insights into data by writing queries over one or multiple tables.",
                    "The practical knowledge needed to combine these skills to manage a basic relational database."
                ]
            }
        ]
    },
    {
        title: "Software Development",
        subheading: "TechTalent Academy",
        id: "tt-software-development",
        date: "June 2022",
        body: [
            {
                type: "p",
                text: "A full-stack software development course aimed at upskilling/reskilling diverse groups and communities to an entry-level standard who are looking for a long-term career in Software Development."
            },
            {
                type: "a",
                text: "View Certificate",
                link: "../view-certificate",
                params: [
                    "name=BrodieGriggs_TTA_Certificate.png"
                ]
            },
            {
                type: "h4",
                text: "Modules Covered"
            },
            {
                type: "ul",
                text: [
                    "HTML and CSS Fundamentals",
                    "JavaScript",
                    "Java",
                    "SQL",
                    "Deployment/Performance Testing",
                    "Intro to Spring Boot"
                ]
            }
        ]
    },
    {
        title: "BCS Foundation Certificate in Agile",
        subheading: "BCS - The Chartered Institute for IT",
        id: "bcs-foundation-certificate-in-agile",
        date: "June 2022",
        body: [
            {
                type: "p",
                text: "A certification to equip candidates with knowledge that can be applied in a variety of situations and promotes effective working within any Agile team."
            },
            {
                type: "a",
                text: "View Certificate",
                link: "../view-certificate",
                params: [
                    "name=BrodieGriggs_Foundation_Agile_Certificate.png"
                ]
            },
            {
                type: "h4",
                text: "Topics Covered:"
            },
            {
                type: "ul",
                text: [
                    "Why Agile? The factors that drive Agile adoption",
                    "The Agile Manifesto in detail",
                    "The Agile mindset",
                    "Roles in Agile teams",
                    "Common Agile practices",
                    "The practical application of Agile"
                ]
            }
        ]
    },
    {
        title: "JavaScript Algorithms and Data Structures",
        subheading: "FreeCodeCamp",
        id: "fcc-javascript-algorithms-and-data-structures",
        date: "February 2022",
        body: [
            {
                type: "p",
                text: "Developer Certification, representing approximately 300 hours of coursework."
            },
            {
                type: "a",
                text: "View Certificate",
                link: "https://www.freecodecamp.org/certification/Brodie_Griggs/javascript-algorithms-and-data-structures"
            },
            {
                type: "h4",
                text: "Modules Covered:"
            },
            {
                type: "ul",
                text: [
                    "Basic JavaScript",
                    "ES6",
                    "Regular Expressions",
                    "Debugging",
                    "Basic Data Structures",
                    "Basic Algorithm Scripting",
                    "Object Oriented Programming",
                    "Functional Programming",
                    "Intermediate Algorithm Scripting",
                    "JavaScript Algorithms and Data Structures Projects"
                ]
            },
            {
                type: "h4",
                text: "Exam Projects:"
            },
            {
                type: "ul",
                text: [
                    "Palindrome Checker",
                    "Roman Numeral Converter",
                    "Caesars Cipher",
                    "Telephone Number Validator",
                    "Cash Register"
                ]
            }
        ]
    },
    {
        divider: true,
        text: "2018"
    },
    {
        title: "BA (Hons) Animation",
        subheading: "The University of Derby",
        id: "uod-ba-hons-animation",
        date: "May 2018",
        body: [
            {
                type: "p",
                text: "BA (Hons) Animation programme, working with the latest digital software to learn about the principles of animation, produce distinctive animated films and develop professional awareness."
            },
            {
                type: "h4",
                text: "Modules Covered:"
            },
            {
                type: "p",
                text: "Year 1"
            },
            {
                type: "ul",
                text: [
                    "Animation Practice",
                    "Visual Exploration",
                    "Visual Culture: History",
                    "Visual Problem Solving"
                ]
            },
            {
                type: "p",
                text: "Year 2"
            },
            {
                type: "ul",
                text: [
                    "Destinations",
                    "Negotiated Study in Animation",
                    "Specialist Practice in Animation",
                    "Visual Culture: Extended Essay"
                ]
            },
            {
                type: "p",
                text: "Year 3"
            },
            {
                type: "ul",
                text: [
                    "Negotiated Study in Animation",
                    "Visual Culture: Extended Essay",
                    "Personal Practice and Methodologies",
                    "Visual Culture: Debates"
                ]
            }
        ]
    },
    {
        divider: true,
        text: "2015"
    },
    {
        title: "A-Levels",
        subheading: "Springwood Sixth Form",
        id: "ssf-a-levels",
        date: "July 2015",
        body: [
            {
                type: "h4",
                text: "3 A-Levels:"
            },
            {
                type: "p",
                text: "Photography A*, Mathematics C, Thinking Skills C"
            },
            {
                type: "h4",
                text: "1 AS-Level:"
            },
            {
                type: "p",
                text: "Geography B"
            }
        ]
    },
    {
        divider: true,
        text: "2013"
    },
    {
        title: "GCSEs",
        subheading: "Springwood High School",
        id: "ssf-gcses",
        date: "July 2013",
        body: [
            {
                type: "p",
                text: "10 GCSEs from grades A* to C, including Mathematics (A*) and English (A)."
            }
        ]
    }
];