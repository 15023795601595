export const SKILLS = [
    {
        title: "HTML / CSS",
        id: "html/css",
        subheading: "Markup and Styling",
        body: [
            {
                type: "p",
                text: "I have more than 5 years of experience working with HTML and CSS since I started learning how to build websites in my spare time. Markup and styling are essential parts of almost every web project I have worked on, so I am well-practiced in utilising them to achieve my project goals."
            },
            {
                type: "h4",
                text: "Projects"
            },
            {
                type: "p",
                text: "I have used HTML and CSS in many projects, but to name a few..."
            },
            {
                type: "ul",
                text: [
                    "A website for the fictional pizza restaurant 'Papa's Pizzeria', developed for an assignment during the TechTalent Software Development Academy.",
                    "My Codepen.io profile is home to lots of projects I have developed, some as part of a certification, others as creative experimentation. Although almost every project I create incorporates HTML and CSS in some way, two that stand out as purely projects experimenting with markup and styling are my Neumorphic UI projects. These are mini projects I have worked on recently experimenting with the fairly new design trend of neumorphism.",
                    "..and also, the website you are viewing right now! This website was built using Gatsby, a React-based open-source framework, but still employs my knowledge in web-page structure and styling."
                ]
            }
        ]
    },
    {
        title: "JavaScript",
        id:  "javascript",
        subheading: "Programming",
        body: [
            {
                type: "p",
                text: "Two years ago I began learning to code using JavaScript. I started by creating interactive games and visualisations using the p5.js library, and quickly branched out into using it to create interactive webpages, UIs and Apps using libraries such as ReactJs."
            },
            {
                type: "h4",
                text: "Projects"
            },
            {
                type: "ul",
                text: [
                    "Projects completed as part of the FreeCodeCamp 'JavaScript Algorithms and Data Structures' Certification, such as a Roman Numeral Converter, Telephone Number Validator or Cash Register.",
                    "My Codepen.io profile is home to lots of projects I have developed, some as part of a certification, others as creative experimentation. Almost all of them incorporate JavaScript in some shape or form, whether it's used for building interactivity, or utilising a library like ReactJs to create interactive web applications."
                ]
            }
        ]
    },
    {
        title: "ReactJs",
        id:  "reactjs",
        subheading: "Programming",
        body: [
            {
                type: "p",
                text: "I have gained experience using ReactJs initially while working on the FreeCodeCamp 'Front End Development Libraries' Certification. Following that initial immersion, I have continued to work with it and strengthen my knowledge, through building my own apps and webpages."
            },
            {
                type: "h4",
                text: "Projects"
            },
            {
                type: "ul",
                text: [
                    "This website: I have built this website using Gatsby, which is a static site generator built on top of Node.js using React and GraphQL. I have used this website as an opportunity to strengthen my skills with ReactJs and also to learn new skills, with Gatsby and GraphQL.",
                    "My codepen.io profile is home to multiple ReactJs apps, built either towards a certification or for my own experimentation. A drum machine, a calculator and a markdown previewer are some of my favourites."
                ]
            }
        ]
    },
    {
        title: "Java",
        id:  "java",
        subheading: "Programming",
        body: [
            {
                type: "p",
                text: "I have experience working on multiple projects with Java during the TechTalent Software Development Academy. Although I this was my first time using Java, I found the transition from JavaScript relatively easy, and was able to quickly pick up the different syntax and class-based structuring. I consistently earned full marks on assignments throughout the academy and I look forward to working on projects in Java and expanding my knowledge in the future."
            },
            {
                type: "h4",
                text: "Projects"
            },
            {
                type: "ul",
                text: [
                    "'Console Explorer', a text-based game about exploring caves. This was a project where the brief was to design a text-based game to test understanding of the basics of Java, however I wanted to challenge myself so I took it to the next level and made a text-based UI and rendered changes in game to the console in the form of a map which is slowly revealed.",
                    "An application to allow a librarian to access a database of books, loans and user and make queries. This project was a test of our ability to create an intuitive application and link it to SQL.",
                    "Testing applications using JUnit. This was a topic covered towards the end of the course, allowing us to test the accuracy and performance of the Java code we had been writing so far. We learnt to write test cases to test functions and create testing suites to perform tests in a batch.",
                    "Creating a simple website using Spring Boot. Spring Boot is an open-source tool that makes it easier for developers to create standalone digital products and production-ready spring applications including Java applications and web services. It uses a micro framework, which makes it most useful for creating microservices for websites and mobile apps.",
                ]
            }
        ]
    },
    {
        title: "Adobe Creative Suite",
        id: "adobe-creative-suite",
        subheading: "Animate | Photoshop | After Effects | Illustrator | InDesign ...",
        body: [
            {
                type: "p",
                text: "[experience summary]"
            },
            {
                type: "h4",
                text: "Projects"
            },
            {
                type: "p",
                text: "[projects]"
            }
        ]
    },
    {
        title: "Blender",
        id:  "blender",
        subheading: "All-purpose 3D Design",
        body: [
            {
                type: "p",
                text: "[experience summary]"
            },
            {
                type: "h4",
                text: "Projects"
            },
            {
                type: "p",
                text: "[projects]"
            }
        ]
    }
];