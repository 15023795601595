import * as React from "react";
import "../styles/global.css";
import "../styles/about.css";
import BgLogoSVG from "../components/svgs/BgLogoSVG";
import CloseSVG from "../components/svgs/CloseSVG.js";
import SocialLink from "../components/SocialLink";
import { QUALIFICATIONS } from "../components/cv_data/QUALIFICATIONS.js";
import { SKILLS } from "../components/cv_data/SKILLS.js";
import { EXPERIENCE } from "../components/cv_data/EXPERIENCE.js";
import { HOBBIES } from "../components/cv_data/HOBBIES.js";
import { LINKS } from '../components/LINKS.js';

const CV_ITEMS = [
  {
    title: "qualifications",
    list: QUALIFICATIONS
  }
  ,
  {
    title: "skills",
    list: SKILLS
  },
  // {
  //   title: "experience",
  //   list: EXPERIENCE
  // },
  // {
  //   title: "hobbies",
  //   list: HOBBIES
  // }
];

const Item = ({ item, handleClick }) => {
  return (
    <button
      id={item.id}
      className="cv-item"
      onClick={() => handleClick(item.id)}
    >
      <h3 className="cv-item-title">{item.title}</h3>
      <p className="cv-item-subheading">{item.subheading}</p>

      <div className="item-highlight"></div>
    </button>
  );
};

const InfoBox = ({ item, handleClick }) => {
  return (
    <div id={item.id + "-info-box"} className="info-box">
      <div className="info-box-header">
        <h3 className="cv-item-title">{item.title}</h3>
        <button
          className="info-close-btn"
          onClick={() => handleClick(item.id)}
        >
          <CloseSVG />
        </button>
        <p className="cv-item-subheading">{item.subheading}</p>
        <p className="info-box-date">{item.date}</p>
      </div>
      <div className="info-box-body">
        
        {item.body.map((element, index) => {
          switch (element.type) {
            case "p":
              return <p key={index}>{element.text}</p>;
            case "h4":
              return <h4 key={index}>{element.text}</h4>;
            case "ul":
              return (
                <ul key={index}>
                  {element.text.map((listItem, idx) => {
                    return <li key={idx}>{listItem}</li>
                  })}
                </ul>
              );
            case "a":
              let urlParams = "";
              if(element.hasOwnProperty('params') && element.params.length > 0) {
                urlParams += "?";
                for(let i = 0; i < element.params.length; i++) {
                  urlParams += element.params[i];
                  if(i < element.params.length - 1) { urlParams += "&"; }
                }
              }
              return <a key={index} href={element.link + urlParams} target="_blank" rel="noreferrer">{element.text}</a>
            default:
              return "";
          }
        })}
      </div>
    </div>
  );
};

const CVSection = ({sectionTitle, itemArr, handleClick}) => {
  return (
    <section id={sectionTitle}>
      <legend>{sectionTitle}</legend>
      {itemArr.map((item, index) => {
        if(item.hasOwnProperty("divider") && item.divider) {
          return (
            <div className="cv-section-divider" >{item.text}</div>
          )
        }
        return (
          <div key={index}>
            <Item
              item={item}
              handleClick={handleClick}
            />
            <InfoBox
              item={item}
              handleClick={handleClick}
            />
          </div>
        )
      })}
    </section>
  )
};

export default function AboutPage() {
  function handleClick(id) {
    const blur = document.getElementById("blur");
    blur.classList.toggle("show");
    const infoBox = document.getElementById(id + "-info-box");
    infoBox.classList.toggle("show");
    const body = document.getElementsByTagName("body")[0];
    body.classList.toggle("block-scrolling");
  }

  return (
    <main id="about">
      <a className="bgLogo" href="../">
        <BgLogoSVG />
      </a>
      {CV_ITEMS.map((section, index) => {
        return (
          <CVSection
            sectionTitle={section.title}
            itemArr={section.list}
            handleClick={handleClick}
            key={index}
          />
        )
      })}

      <a id="home-btn" href="../"><p>back</p></a>
      <nav className="social-links">
        {LINKS.map((link, index) => {
          return <SocialLink
          socialName={link.text}
          url={link.url}
          iconSVG={link.icon}
          key={index}/>
        })}
      </nav>
      <div id="blur" />
    </main>
  );
}

export const Head = () => <title>Brodie Griggs</title>;